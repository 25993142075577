import {
  Locale as BackendLocale,
  Location as BackendLocation,
  type Collage,
  type LocaleInfo,
  type Maybe,
  type StickerGroup,
  type StickerOnCollage,
} from '@/graphql/generated_villus'
import { Locale as DittoLocale, getCurrentLocale } from '@/locale'

export const backendLocaleMappingDitto: Record<BackendLocale, DittoLocale> = {
  [BackendLocale.Tw]: DittoLocale.TW,
  [BackendLocale.Jp]: DittoLocale.JP,
  [BackendLocale.Th]: DittoLocale.TH,
  [BackendLocale.Asia]: DittoLocale.ASIA,
  [BackendLocale.Io]: DittoLocale.IO,
  [BackendLocale.Eu]: DittoLocale.EU,
  [BackendLocale.Uk]: DittoLocale.UK,
  [BackendLocale.Fr]: DittoLocale.FR,
  [BackendLocale.De]: DittoLocale.DE,
  [BackendLocale.Es]: DittoLocale.ES,
  [BackendLocale.Kr]: DittoLocale.KR,
}

export const backendLocationMappingDitto: Record<
  Exclude<BackendLocation, BackendLocation.All>,
  DittoLocale
> = {
  [BackendLocation.Tw]: DittoLocale.TW,
  [BackendLocation.Jp]: DittoLocale.JP,
  [BackendLocation.Th]: DittoLocale.TH,
  [BackendLocation.Asia]: DittoLocale.ASIA,
  [BackendLocation.Io]: DittoLocale.IO,
  [BackendLocation.Eu]: DittoLocale.EU,
  [BackendLocation.Uk]: DittoLocale.UK,
  [BackendLocation.Fr]: DittoLocale.FR,
  [BackendLocation.De]: DittoLocale.DE,
  [BackendLocation.Es]: DittoLocale.ES,
  [BackendLocation.Kr]: DittoLocale.KR,
}

export const dittoMappingBackendLocale: Record<DittoLocale, BackendLocale> = {
  [DittoLocale.TW]: BackendLocale.Tw,
  [DittoLocale.JP]: BackendLocale.Jp,
  [DittoLocale.TH]: BackendLocale.Th,
  [DittoLocale.ASIA]: BackendLocale.Asia,
  [DittoLocale.IO]: BackendLocale.Io,
  [DittoLocale.EU]: BackendLocale.Eu,
  [DittoLocale.UK]: BackendLocale.Uk,
  [DittoLocale.FR]: BackendLocale.Fr,
  [DittoLocale.DE]: BackendLocale.De,
  [DittoLocale.ES]: BackendLocale.Es,
  [DittoLocale.KR]: BackendLocale.Kr,
}

export const dittoMappingBackendLocation: Record<
  DittoLocale,
  Exclude<BackendLocation, BackendLocation.All>
> = {
  [DittoLocale.TW]: BackendLocation.Tw,
  [DittoLocale.JP]: BackendLocation.Jp,
  [DittoLocale.TH]: BackendLocation.Th,
  [DittoLocale.ASIA]: BackendLocation.Asia,
  [DittoLocale.IO]: BackendLocation.Io,
  [DittoLocale.EU]: BackendLocation.Eu,
  [DittoLocale.UK]: BackendLocation.Uk,
  [DittoLocale.FR]: BackendLocation.Fr,
  [DittoLocale.DE]: BackendLocation.De,
  [DittoLocale.ES]: BackendLocation.Es,
  [DittoLocale.KR]: BackendLocation.Kr,
}

type SimpleLocaleInfo = null | Pick<LocaleInfo, 'amount' | 'compareAtAmount' | 'locale'>
export function localeInfoWithCurrentLocale(
  prices: Maybe<SimpleLocaleInfo[]> | undefined,
): null | SimpleLocaleInfo {
  if (prices === null || prices === undefined) {
    return null
  }
  const currentLocale = getCurrentLocale()
  const currentPrice = prices.find(
    (item) => item !== null && backendLocaleMappingDitto[item.locale] === currentLocale,
  )
  return currentPrice ?? null
}

interface SimplePricePlan {
  localeInfo?: Array<Maybe<Pick<LocaleInfo, 'amount' | 'compareAtAmount' | 'locale'>>> | null
}

export type CollageForPricing = Pick<Collage, 'backgroundVisible'> & {
  background: null | undefined | { pricePlan?: null | SimplePricePlan }
} & {
  stickerOnCollages: Array<
    Pick<StickerOnCollage, 'visible'> & {
      stickerGroup: Pick<StickerGroup, 'id'> & {
        pricePlan?: null | SimplePricePlan
      }
    }
  >
}
export function calculateCollagePrice(
  collage: CollageForPricing,
): null | Record<'compareAtPrice' | 'price', number> {
  let price = 0
  let compareAtPrice = 0
  if (
    collage.backgroundVisible &&
    collage.background !== undefined &&
    collage.background !== null
  ) {
    const background = localeInfoWithCurrentLocale(collage.background.pricePlan?.localeInfo)
    if (background === null) {
      return null
    }
    price += background.amount
    compareAtPrice += background.compareAtAmount
  }

  // 為了讓價格去重複用的
  const groupIds = new Set()
  for (const stickerItem of collage.stickerOnCollages) {
    if (stickerItem.visible && !groupIds.has(stickerItem.stickerGroup.id)) {
      groupIds.add(stickerItem.stickerGroup.id)
      const sticker = localeInfoWithCurrentLocale(stickerItem.stickerGroup.pricePlan?.localeInfo)
      if (sticker === null) {
        return null
      }
      price += sticker.amount
      compareAtPrice += sticker.compareAtAmount
    }
  }
  return {
    price,
    compareAtPrice,
  }
}

export const backendLocale: BackendLocale = dittoMappingBackendLocale[getCurrentLocale()]

export const backendLocation: BackendLocation = dittoMappingBackendLocation[getCurrentLocale()]

export function isLocalItem(x: { location: BackendLocation }): boolean {
  return x.location === backendLocation
}
