import dompurify from 'dompurify'
import UAParser from 'ua-parser-js'

import { ArtworkType } from '@/graphql/generated_villus'

export const popularList = [
  'The Girl',
  'LAZY MONSTER',
  'wohenlanduo',
  'Jin Jin Tattoo',
  'Rachel Liao',
]

export function cleanTranslation(dirty: string): string {
  return dompurify.sanitize(dirty, {
    ALLOWED_TAGS: ['a', 'u', 'span', 'br'],
    ALLOWED_ATTR: ['target', 'href', 'class'],
  })
}

const unsupportedBrowser = new Set([
  'Firefox',
  'Quark',
  'Samsung Browser',
  'Samsung Internet',
  'TikTok',
])
export function isUnsupportedBrowser(): boolean {
  const ua = new UAParser(navigator.userAgent)
  const browser = ua.getBrowser().name ?? ''
  return unsupportedBrowser.has(browser)
}

export function isArtworkType(type: unknown): type is ArtworkType {
  const types: string[] = Object.values(ArtworkType)
  return typeof type === 'string' && types.includes(type)
}

export const defaultAvatar = '/default-avatar.svg'

export function isURL(value: unknown): value is `https://${string}` {
  const urlPattern = /^https:\/\/[^\s/$.?#].[^\s]*$/i
  return typeof value === 'string' && urlPattern.test(value)
}
